import React from "react";
import { Container, Row } from "react-bootstrap";
import StatsComponent from "../components/StatsComponent";
import MultiItemCarousel from "../components/MultiItemCarousel";
import { useSelector } from "react-redux";

const AboutsUs = () => {
  const { translations } = useSelector((state) => state.language);
  const { language } = useSelector((state) => state.language);

  const images = [
    {
      image1: require("../images/aboutus/111.jpg"),
    },
    {
      image1: require("../images/aboutus/2222.jpg"),
    },
  ];

  const employs = [
    {
      name: translations.irakliUbilava,
      position: translations.managingPartner,
      img: require("../images/aboutus/employsPhotos/irakli-ubilava.jpg"),
      linkdinURL: "https://www.linkedin.com/in/irakli-ubilava-818015153/",
    },
    {
      name: translations.irakliBabunashvili,
      position: translations.technicalDirector,
      img: require("../images/aboutus/employsPhotos/irakli-babunashvili.jpg"),
      linkdinURL: "https://www.linkedin.com/in/babuna/",
    },
    {
      name: translations.davitMarkhulia,
      position: translations.operationsManager,
      img: require("../images/aboutus/employsPhotos/david-marxulia.jpg"),
      linkdinURL: "https://www.linkedin.com/in/dmarkhulia/",
    },
    {
      name: translations.davitBardavelidze,
      position: translations.salesManager,
      img: require("../images/aboutus/employsPhotos/david-bardavelidze.jpg"),
      linkdinURL: "https://www.linkedin.com/in/davit-bardavelidze-6064221a6/",
    },
  ];

  return (
    <Container fluid className="about-us-container">
      <Row className="about-us-row about-us-row-1 pb-5">
        {/* <div className="about-us-title">
        </div> */}
        <div className="about-us-img-text-container about-us-img-text-container-1">
          <div className="about-us-text-container">
            <h3 className="text-center about-us-ohmenergy-title mb-4">{translations.ohmEnergy}</h3>
            <p className="mb-0">
              {/* <span className="about-us-ohm-energu-span">OHM ENERGY </span> */}
              {translations.aboutUsDescription}
            </p>
          </div>
          <div className="about-us-img-1-container">
            <div className="about-us-img-1-inner-container">
              <img src={images[0].image1} className="about-us-img-1" alt="" />
            </div>
          </div>
        </div>
      </Row>
      <Row>
        <StatsComponent />
      </Row>
      <Row className="about-us-row about-us-row-2 py-5">
        <div className="about-us-img-text-container  about-us-img-text-container-2">
          <div className="about-us-text-container">
            <h5 className="fw-bold me-auto mb-4 about-us-our-vision-h5">{translations.vision}</h5>
            <p>{translations.aboutUsVision}</p>
          </div>
          <div className="about-us-img-1-container">
            <div className="about-us-img-1-inner-container">
              <img src={images[1].image1} className="about-us-img-1" alt="" />
            </div>
          </div>
        </div>
      </Row>
      <Row className="about-us-row about-us-row-3 pb-5">
        <MultiItemCarousel employs={employs} language={language} />
      </Row>
    </Container>
  );
};

export default AboutsUs;
