import { createSlice } from "@reduxjs/toolkit";
import en from "../translations/en";
import geo from "../translations/geo";

const initialState = [
  {
    name: {
      en: en.residentialHouseTitle,
      geo: geo.residentialHouseTitle,
    },
    capacity: 10,
    location: {
      en: en.ResidentialHouseLocation,
      geo: geo.ResidentialHouseLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.ResidentialHouseSolarModule,
      geo: geo.ResidentialHouseSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.residential,
      geo: geo.residential,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/house.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/house-1.jpg"),
      require("../images/projects/singleProjects/house-2.jpg"),
    ],
  },
  {
    name: {
      en: en.megadorisiTitle,
      geo: geo.megadorisiTitle,
    },
    capacity: 130,
    location: {
      en: en.megadorisiLocation,
      geo: geo.megadorisiLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.megadorisiModule,
      geo: geo.megadorisiModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/megadorisi.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/megadorisi-1.jpg"),
      require("../images/projects/singleProjects/megadorisi-2.jpg"),
    ],
  },
  {
    name: {
      en: en.citroenTItle,
      geo: geo.citroenTItle,
    },
    capacity: 50,
    location: {
      en: en.citroenLocation,
      geo: geo.citroenLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.citroenSolarModule,
      geo: geo.citroenSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "Huawei",
      geo: "Huawei",
      arm: "Huawei 100 կՎտ ինվերտոր, 30 հատ",
      rus: "Huawei 100 кВт инвертор, 30 шт.",
    },
    annualElectricityProduction: "5550",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    // description: {
    //   en: [],
    //   geo: [],
    //   arm: ["հայերեն լոռեմ իպսում"],
    //   rus: ["другой текст Lorem Ipsum"],
    // },
    type: {
      en: en.commercial,
      geo: en.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/citroen.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/citroen-1.jpg"),
      require("../images/projects/singleProjects/citroen-2.jpg"),
      require("../images/projects/singleProjects/citroen-3.jpg"),
    ],
  },
  {
    name: {
      en: en.zhinvaliTItle,
      geo: geo.zhinvaliTItle,
    },
    capacity: 66,
    location: {
      en: en.zhinvaliLocation,
      geo: geo.zhinvaliLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.zhinvaliSolarModule,
      geo: geo.zhinvaliSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "Huawei",
      geo: "Huawei",
      arm: "Huawei 100 կՎտ ինվերտոր, 30 հատ",
      rus: "Huawei 100 кВт инвертор, 30 шт.",
    },
    annualElectricityProduction: "5550",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/zhinvali.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/zhinvali-1.jpg"),
      require("../images/projects/singleProjects/zhinvali-2.jpg"),
    ],
  },
  {
    name: {
      en: en.marneuliNurseryTitle,
      geo: geo.marneuliNurseryTitle,
    },
    capacity: 42,
    location: {
      en: en.marneuliNurseryLocation,
      geo: geo.marneuliNurseryLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.marneuliNurserySolarModule,
      geo: geo.marneuliNurserySolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "Huawei SUN2000",
      geo: "Huawei SUN2000",
      arm: "Huawei SUN2000",
      rus: "Huawei SUN2000",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/marneuli.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/marneuli-1.jpg"),
      require("../images/projects/singleProjects/marneuli-2.jpg"),
      require("../images/projects/singleProjects/marneuli-3.jpg"),
    ],
  },
  {
    name: {
      en: en.mermisiTitle,
      geo: geo.mermisiTitle,
    },
    capacity: 60,
    location: {
      en: en.mermisiLocation,
      geo: geo.mermisiLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.mermisiSolarModule,
      geo: geo.mermisiSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/mermisi.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/mermisi-1.jpg"),
      require("../images/projects/singleProjects/mermisi-2.jpg"),
    ],
  },
  {
    name: {
      en: en.tbilisiHillsTitle,
      geo: geo.tbilisiHillsTitle,
    },
    capacity: 500,
    location: {
      en: en.tbilisiHillsLocation,
      geo: geo.tbilisiHillsLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.tbilisiHillsSolarModule,
      geo: geo.tbilisiHillsSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    // description: {
    //   en: [],
    //   geo: [],
    //   arm: ["հայերեն լոռեմ իպսում"],
    //   rus: ["другой текст Lorem Ipsum"],
    // },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/tbilisi-hills.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/tbilisi-hills-1.jpg"),
      require("../images/projects/singleProjects/tbilisi-hills-2.jpg"),
      require("../images/projects/singleProjects/tbilisi-hills-3.jpg"),
      require("../images/projects/singleProjects/tbilisi-hills-4.jpg"),
    ],
  },
  {
    name: {
      en: en.toyotaCenterTitle,
      geo: geo.toyotaCenterTitle,
    },
    capacity: 300,
    location: {
      en: en.toyotaCenterLocation,
      geo: geo.toyotaCenterLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.toyotaCenterSolarModule,
      geo: geo.toyotaCenterSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/toyota-center-tbilisi.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/toyota-center-tbilisi-1.jpg"),
      require("../images/projects/singleProjects/toyota-center-tbilisi-2.jpg"),
      require("../images/projects/singleProjects/toyota-center-tbilisi-3.jpg"),
    ],
  },
  {
    name: {
      en: en.altaTitle,
      geo: geo.altaTitle,
    },
    capacity: 110,
    location: {
      en: en.altaLocation,
      geo: geo.altaLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.altaSolarModule,
      geo: geo.altaSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/alta.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/alta-1.jpg"),
      require("../images/projects/singleProjects/alta-2.jpg"),
    ],
  },
  {
    name: {
      en: en.fazisiTitle,
      geo: geo.fazisiTitle,
    },
    capacity: 20,
    location: {
      en: en.fazisiLocation,
      geo: geo.fazisiLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: en.fazisiSolarModule,
      geo: geo.fazisiSolarModule,
      arm: "AE SOLAR 450 Վ, մոնոկրիստալային 6820 հատ, Գերմանիա",
      rus: "AE SOLAR 450 Вт, монокристаллические 6820 шт, Германия",
    },
    inverter: {
      en: "---",
      geo: "---",
      arm: "---",
      rus: "---",
    },
    annualElectricityProduction: "---",
    mwh: {
      en: en.mwh,
      geo: geo.mwh,
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [],
      geo: [],
      arm: ["հայերեն լոռեմ իպսում"],
      rus: ["другой текст Lorem Ipsum"],
    },
    type: {
      en: en.commercial,
      geo: geo.commercial,
      arm: "տնային",
      rus: "жилой",
    },
    imageSrc: require("../images/projects/fazisi.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/fazisi-1.jpg"),
      require("../images/projects/singleProjects/fazisi-2.jpg"),
    ],
  },
  {
    name: {
      en: en.rustaviResidentialTitle,
      arm: `---`,
      geo: geo.rustaviResidentialTitle,
      rus: `---`,
    },
    kw: { en: "kw", geo: "კვტ", arm: "կՎտ", rus: "кВт" },
    capacity: 10,
    location: {
      en: en.rustaviResidentialLocation,
      geo: geo.rustaviResidentialLocation,
      arm: "Մերձավան, Արմավիրի մարզ",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: "--",
      arm: "--",
      geo: "--",
      rus: "--",
    },
    inverter: {
      en: "--",
      arm: "--",
      geo: "--",
      rus: "--",
    },
    annualElectricityProduction: "--",
    mwh: {
      en: "MWh",
      geo: "მგვტ.სთ",
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [""],
      arm: [""],
      geo: [""],
      rus: [""],
    },
    type: {
      en: en.residential,
      geo: geo.residential,
      arm: "մեծ ծավալի",
      rus: "крупномасштабный",
    },
    imageSrc: require("../images/projects/rustavi-residential.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/rustavi-residential-1.jpg"),
    ],
  },
  {
    name: {
      en: `"Sun Energy"`,
      arm: `"Արևային Էներգիա"`,
      geo: `"Sun Energy"`,
      rus: `"Солнечная энергия"`,
    },
    kw: { en: "kw", geo: "კვტ", arm: "կՎտ", rus: "кВт" },
    capacity: 3000,
    location: {
      en: "Merdzavan, Armenia",
      arm: "Մերձավան, Արմավիրի մարզ",
      geo: "მერძავანი , სომხეთი",
      rus: "Мердзаван, Армавирская область",
    },
    solarModules: {
      en: "AE SOLAR 450 W, monocrystalline 6820 pcs, Germany",
      arm: "AE SOLAR 450 Վտ, մոնոկրիստալին 6820 հատ, Գերմանիա",
      geo: "AE SOLAR 450 W, მონოკრისტალინი 6820 ცალი, გერმანია",
      rus: "AE SOLAR 450 Вт, монокристаллический 6820 шт, Германия",
    },
    inverter: {
      en: "Huawei 100 kW inverter, 30 pcs.",
      arm: "Huawei 100 կՎտ ինվերտոր, 30 հատ",
      geo: "Huawei 100 კვტ ინვერტორი, 30 ცალი",
      rus: "Huawei 100 кВт инвертор, 30 шт.",
    },
    annualElectricityProduction: "5550",
    mwh: {
      en: "MWh",
      geo: "მგვტ.სთ",
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [
        `The installation of 3 MW power plant was made with 60 blocks (inverters). The Power plant is equipped with accident-prevention systems to avoid short circuit, non-sinusoidal currents, overvoltages, abrupt voltage changes, zero wire breakage problems, and other failures.`,
        `In the surrounding area must be installed an earth ground loop with a resistance less than 4 ohms for AC and DC circuits. The generated capacity is transferred to the "Spandaryan" substation.`,
      ],
      arm: [
        `3 ՄՎտ հզորությամբ էլեկտրակայանի տեղադրումը կատարվել է 60 բլոկով (ինվերտորներ): Էլեկտրակայանը հագեցած է վթարային կանխարգելման համակարգերով՝ կարճ միացման, ոչ-սինուսային հոսանքների, ավելորդ լարման, կտրուկ լարման փոփոխությունների, զրոյական լարման խզման խնդիրների և այլ անսարքությունների կանխարգելման համար։`,
        `Շրջակայքում պետք է տեղադրվի հողի օղակ՝ <4 օհմ դիմադրությամբ, ինչպես փոփոխական, այնպես էլ անմիջական հոսանքի համար: Սահմանված հզորությունը փոխանցվում է "Սպանդարյան" ենթակայանին։`,
      ],
      geo: [
        `3 მგვტ სიმძლავრის ელექტროსადგურის მონტაჟი განხორციელდა 60 ბლოკით (ინვერტორები). ელექტროსადგური აღჭურვილია ავარიების პრევენციის სისტემებით მოკლე ჩართვის, არასინუსოიდური დენების, ზედმეტი ძაბვის, მკვეთრი ძაბვის ცვლილებების, ნულოვანი მავთულის გაწყვეტის პრობლემებისა და სხვა დარღვევების თავიდან ასაცილებლად.`,
        `გარშემო ტერიტორიაზე უნდა დაიდგას მიწის კარიანი რგოლი <4 ომი წინააღმდეგობით, როგორც AC, ასევე DC წრედებისთვის. გამომუშავებული სიმძლავრე გადაეცემა "სპანდარიანის" ქვესადგურს.`,
      ],
      rus: [
        `Установка 3 МВт электростанции выполнена с 60 блоками (инверторами). Электростанция оснащена системами аварийной профилактики для предотвращения коротких замыканий, несинусоидальных токов, перенапряжений, резких изменений напряжения, проблем обрыва нулевого провода и других отказов.`,
        `На прилегающей территории должен быть установлен заземляющий контур с сопротивлением менее 4 Ом для цепей переменного и постоянного тока. Вырабатываемая мощность передается на подстанцию "Спандарян".`,
      ],
    },
    type: {
      en: en.largeScale,
      geo: geo.largeScale,
      arm: "մեծ ծավալի",
      rus: "крупномасштабный",
    },
    imageSrc: require("../images/projects/merdzava.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/merdzavan-1.jpg"),
      require("../images/projects/singleProjects/merdzavan-2.jpg"),
      require("../images/projects/singleProjects/merdzavan-3.jpg"),
      require("../images/projects/singleProjects/merdzavan-4.jpg"),
      require("../images/projects/singleProjects/merdzavan-5.jpg"),
      require("../images/projects/singleProjects/merdzavan-6.jpg"),
      require("../images/projects/singleProjects/merdzavan-7.jpg"),
      require("../images/projects/singleProjects/merdzavan-8.jpg"),
      require("../images/projects/singleProjects/merdzavan-9.jpg"),
      require("../images/projects/singleProjects/merdzavan-10.jpg"),
      require("../images/projects/singleProjects/merdzavan-10.jpg"),
      require("../images/projects/singleProjects/merdzavan-11.jpg"),
      require("../images/projects/singleProjects/merdzavan-12.jpg"),
      require("../images/projects/singleProjects/merdzavan-13.jpg"),
    ],
  },
  {
    name: {
      en: `"Solar Farm"`,
      geo: `"Solar Farm"`,
      arm: `"Արևային Ֆերմա"`,
      rus: `"Солнечная ферма"`,
    },
    videoSrc: "https://www.youtube.com/embed/RAO_aOzBItE",
    kw: { en: "kw", geo: "კვტ", arm: "կՎտ", rus: "кВт" },
    capacity: 5000,
    location: {
      en: "Shorja, Armenia",
      geo: "შორჯა, სომხეთი",
      arm: "Շորժա, Գեղարքունիքի մարզ",
      rus: "Шоржа, Гехаркуникская область",
    },
    solarModules: {
      en: "AE SOLAR 380 W, monocrystalline 15780 pcs, Germany",
      geo: "AE SOLAR 380 W, მონოკრისტალური 15780 ცალი, გერმანია",
      arm: "AE SOLAR 380 Վ, մոնոկրիստալային 15780 հատ, Գերմանիա",
      rus: "AE SOLAR 380 Вт, монокристаллические 15780 шт, Германия",
    },
    inverter: {
      en: "Huawei 100 kW inverter, 50 pcs",
      geo: "Huawei 100 კვტ ინვერტორი, 50 ცალი",
      arm: "Huawei 100 կՎտ ինվերտոր, 50 հատ",
      rus: "Huawei 100 кВт инвертор, 50 шт.",
    },
    annualElectricityProduction: "9700",
    mwh: {
      en: "MWh",
      geo: "მგვტ.სთ",
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [
        `The installation of 6 MW pick power (5 MW nominal power) plant was made with 100 blocks (inverters). The Power plant is equipped with accident-prevention systems to avoid short circuit, non-sinusoidal currents, overvoltages, abrupt voltage changes, zero wire breakage problems, and other failures.`,
        `In the surrounding area was installed an earth ground loop with a resistance less than 4 ohms for AC and DC circuits.`,
      ],
      geo: [
        `6 მგვტ პიკური სიმძლავრის (5 მგვტ ნომინალური სიმძლავრის) სადგურის მონტაჟი განხორციელდა 100 ბლოკით (ინვერტორებით). ელექტროსადგური აღჭურვილია ავარიების თავიდან ასაცილებელი სისტემებით, რათა თავიდან იქნას აცილებული მოკლე ჩართვა, არასინუსოიდალური დენები, ზედმეტი ძაბვები, უეცარი ძაბვის ცვლილებები, ნულოვანი მავთულის გაწყვეტის პრობლემები და სხვა გაუმართაობები.`,
        `მდებარე ტერიტორიაზე დამონტაჟდა მიწის საკომუნიკაციო მარყუჟი, რომლის წინაღობა 4 ომზე ნაკლებია AC და DC წრედებისთვის.`,
      ],
      arm: [
        `6 ՄՎտ պիկային հզորության (5 ՄՎտ անվանական հզորության) կայանի տեղադրումն իրականացվել է 100 բլոկով (ինվերտորներով): Կայանը համալրված է վթարների կանխարգելման համակարգերով՝ կարճ միացումներից, ոչ սինուսոիդալ հոսանքներից, գերլարումներից, հանկարծակի լարման փոփոխություններից, զրոյական մետաղալարերի կոտրվածքների և այլ խափանումներից խուսափելու համար:`,
        `Շրջապատող տարածքում տեղադրվել է հողային գծի շրջանակ՝ 4 օմից պակաս դիմադրությամբ AC և DC շղթաների համար:`,
      ],
      rus: [
        `Установка электростанции мощностью 6 МВт (номинальной мощностью 5 МВт) выполнена с 100 блоками (инверторами). Электростанция оснащена системами предотвращения аварий для избежания короткого замыкания, несинусоидальных токов, перенапряжений, резких изменений напряжения, обрывов нулевых проводов и других неисправностей.`,
        `На прилегающей территории установлена заземляющая петля с сопротивлением менее 4 Ом для цепей переменного и постоянного тока.`,
      ],
    },
    type: {
      en: en.largeScale,
      geo: geo.largeScale,
      arm: "մեծ ծավալի",
      rus: "крупномасштабный",
    },
    imageSrc: require("../images/projects/shorzha.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/shorzha-1.jpg"),
      require("../images/projects/singleProjects/shorzha-2.jpg"),
      require("../images/projects/singleProjects/shorzha-3.jpg"),
      require("../images/projects/singleProjects/shorzha-4.jpg"),
      require("../images/projects/singleProjects/shorzha-5.jpg"),
      require("../images/projects/singleProjects/shorzha-6.jpg"),
      require("../images/projects/singleProjects/shorzha-7.jpg"),
      require("../images/projects/singleProjects/shorzha-8.jpg"),
      require("../images/projects/singleProjects/shorzha-9.jpg"),
      require("../images/projects/singleProjects/shorzha-10.jpg"),
      require("../images/projects/singleProjects/shorzha-10.jpg"),
      require("../images/projects/singleProjects/shorzha-11.jpg"),
      require("../images/projects/singleProjects/shorzha-12.jpg"),
      require("../images/projects/singleProjects/shorzha-13.jpg"),
      require("../images/projects/singleProjects/shorzha-14.jpg"),
    ],
  },
  {
    name: {
      en: `"Module Sun"`,
      geo: `"Module Sun"`,
      arm: `"Module Sun" ՍՊԸ, Գեղարքունիքի մարզ, Մետս Մասրիկ գյուղ`,
      rus: `"Module Sun" ООО, Гехаркуникская область, село Мец Масрик`,
    },
    kw: {
      en: "kw",
      geo: "კვტ",
      arm: "կՎտ",
      rus: "кВт",
    },
    capacity: 3500,
    location: {
      en: "Masrik, Armenia",
      geo: "მეც მასრიკი, სომხეთი",
      arm: "Գեղարքունիքի մարզ, Մետս Մասրիկ գյուղ",
      rus: "Гехаркуникская область, село Мец Масрик",
    },
    solarModules: {
      en: "Longi Solar 535 W, bifacial, monocrystalline 6550 pcs",
      geo: "Longi Solar 535 W, ბიფაციური, მონოკრისტალური 6550 ცალი",
      arm: "Longi Solar 535 Վ, երկკողმიანი, մոնոկրիստալային 6550 հատ",
      rus: "Longi Solar 535 Вт, двухсторонние, монокристаллические 6550 шт.",
    },
    inverter: {
      en: "Huawei 215 kW inverter, 14 pcs",
      geo: "Huawei 215 კვტ ინვერტორი, 14 ცალი",
      arm: "Huawei 215 կՎտ ինվերտոր, 14 հատ",
      rus: "Huawei 215 кВт инвертор, 14 шт.",
    },
    annualElectricityProduction: "6330",
    mwh: {
      en: "MWh",
      geo: "მგვტ.სთ",
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [
        `The installation of 3.5 MW pick power (3 MW nominal power) plant was made with 14 blocks (inverters). The Power plant is equipped with accident-prevention systems to avoid short circuit, non-sinusoidal currents, overvoltages, abrupt voltage changes, zero wire breakage problems, and other failures.`,
        `In the surrounding area was installed an earth ground loop with a resistance less than 4 ohms for AC and DC circuits.`,
      ],
      geo: [
        `3.5 მგვტ პიკური სიმძლავრის (3 მგვტ ნომინალური სიმძლავრის) სადგურის მონტაჟი განხორციელდა 14 ბლოკით (ინვერტორებით). ელექტროსადგური აღჭურვილია ავარიების თავიდან ასაცილებელი სისტემებით, რათა თავიდან იქნას აცილებული მოკლე ჩართვა, არასინუსოიდალური დენები, ზედმეტი ძაბვები, უეცარი ძაბვის ცვლილებები, ნულოვანი მავთულის გაწყვეტის პრობლემები და სხვა გაუმართაობები.`,
        `მდებარე ტერიტორიაზე დამონტაჟდა მიწის საკომუნიკაციო მარყუჟი, რომლის წინაღობა 4 ომზე ნაკლებია AC და DC წრედებისთვის.`,
      ],
      arm: [
        `3.5 ՄՎտ պիկային հզորության (3 ՄՎտ անվանական հզորության) կայանի տեղադրումն իրականացվել է 14 բլոկով (ինվերտորներով): Կայանը համալրված է վթարների կանխարգելման համակարգերով՝ կարճ միացումներից, ոչ սինուսոիդալ հոսանքներից, գերլարումներից, հանկարծակի լարման փոփոխություններից, զրոյական մետաղալարերի կոտրվածքների և այլ խափանումներից խուսափելու համար:`,
        `Շրջապատող տարածքում տեղադրվել է հողային գծի շրջանակ՝ 4 օմից պակաս դիմադրությամբ AC և DC շղթաների համար:`,
      ],
      rus: [
        `Установка электростанции мощностью 3,5 МВт (номинальной мощностью 3 МВт) выполнена с 14 блоками (инверторами). Электростанция оснащена системами предотвращения аварий для избежания короткого замыкания, несинусоидальных токов, перенапряжений, резких изменений напряжения, обрывов нулевых проводов и других неисправностей.`,
        `На прилегающей территории установлена заземляющая петля с сопротивлением менее 4 Ом для цепей переменного и постоянного тока.`,
      ],
    },
    type: {
      en: en.largeScale,
      geo: geo.largeScale,
      arm: "մեծ ծավալի",
      rus: "крупномасштабный",
    },
    imageSrc: require("../images/projects/masrik.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/masrik-1.jpg"),
      require("../images/projects/singleProjects/masrik-2.jpg"),
      require("../images/projects/singleProjects/masrik-3.jpg"),
      require("../images/projects/singleProjects/masrik-4.jpg"),
      require("../images/projects/singleProjects/masrik-5.jpg"),
      require("../images/projects/singleProjects/masrik-6.jpg"),
      require("../images/projects/singleProjects/masrik-7.jpg"),
      require("../images/projects/singleProjects/masrik-8.jpg"),
      require("../images/projects/singleProjects/masrik-9.jpg"),
      require("../images/projects/singleProjects/masrik-10.jpg"),
      require("../images/projects/singleProjects/masrik-10.jpg"),
      require("../images/projects/singleProjects/masrik-11.jpg"),
      require("../images/projects/singleProjects/masrik-12.jpg"),
      require("../images/projects/singleProjects/masrik-13.jpg"),
    ],
  },

  {
    name: {
      en: `"G.Solar"`,
      geo: `"G.Solar"`,
      arm: `"G.Solar"`,
      rus: `"G.Solar"`,
    },
    kw: {
      en: "kW",
      geo: "კვტ",
      arm: "կՎտ",
      rus: "кВт",
    },
    capacity: 800,
    location: {
      en: "Karmrashen, Armenia",
      geo: "კარმრაშენი, სომხეთი",
      arm: "Կարմրաշեն, Արագածոտնի մարզ",
      rus: "Кармрашен, Арагацотнская область",
    },
    solarModules: {
      en: "AE SOLAR 380 W, monocrystalline 2106 pcs, Germany",
      geo: "AE SOLAR 380 W, მონოკრისტალური 2106 ცალი, გერმანია",
      arm: "AE SOLAR 380 Վ, մոնոկրիստալային 2106 հատ, Գերմանիա",
      rus: "AE SOLAR 380 Вт, монокристаллические 2106 шт, Германия",
    },
    inverter: {
      en: "SMA Sunny Tripower CORE1 10 pcs and Sunny Highpower Peak1 SHP 75 4 pcs, Germany",
      geo: "SMA Sunny Tripower CORE1 10 ცალი და Sunny Highpower Peak1 SHP 75 4 ცალი, გერმანია",
      arm: "SMA Sunny Tripower CORE1 10 հատ և Sunny Highpower Peak1 SHP 75 4 հատ, Գերմանիա",
      rus: "SMA Sunny Tripower CORE1 10 шт. и Sunny Highpower Peak1 SHP 75 4 шт., Германия",
    },
    annualElectricityProduction: "1480",
    mwh: {
      en: "MWh",
      geo: "მგვტ.სთ",
      arm: "ՄՎտ/ժ",
      rus: "МВт·ч",
    },
    description: {
      en: [
        `The installation of 800 kW power plant was made with 14 blocks (inverters). The Power plant is equipped with accident-prevention systems to avoid short circuit, non-sinusoidal currents, overvoltages, abrupt voltage changes, zero wire breakage problems, and other failures.`,
        `In the surrounding area was installed an earth ground loop with a resistance less than 4 ohms for AC and DC circuits.`,
      ],
      geo: [
        `800 კვტ სიმძლავრის სადგურის მონტაჟი განხორციელდა 14 ბლოკით (ინვერტორებით). ელექტროსადგური აღჭურვილია ავარიების თავიდან ასაცილებელი სისტემებით, რათა თავიდან იქნას აცილებული მოკლე ჩართვა, არასინუსოიდალური დენები, ზედმეტი ძაბვები, უეცარი ძაბვის ცვლილებები, ნულოვანი მავთულის გაწყვეტის პრობლემები და სხვა გაუმართაობები.`,
        `მდებარე ტერიტორიაზე დამონტაჟდა მიწის საკომუნიკაციო მარყუჟი, რომლის წინაღობა 4 ომზე ნაკლებია AC და DC წრედებისთვის.`,
      ],
      arm: [
        `800 կՎտ հզորության կայանի տեղադրումն իրականացվել է 14 բլոկով (ինվերտորներով): Կայանը համալրված է վթարների կանխարգելման համակարգերով՝ կարճ միացումներից, ոչ սինուսոիդալ հոսանքներից, գերլարումներից, հանկարծակի լարման փոփոխություններից, զրոյական մետաղալարերի կոտրվածքների և այլ խափանումներից խուսափելու համար:`,
        `Շրջապատող տարածքում տեղադրվել է հողային գծի շրջանակ՝ 4 օմից պակաս դիմադրությամբ AC և DC շղթաների համար:`,
      ],
      rus: [
        `Установка электростанции мощностью 800 кВт выполнена с 14 блоками (инверторами). Электростанция оснащена системами предотвращения аварий для избежания короткого замыкания, несинусоидальных токов, перенапряжений, резких изменений напряжения, обрывов нулевых проводов и других неисправностей.`,
        `На прилегающей территории установлена заземляющая петля с сопротивлением менее 4 Ом для цепей переменного и постоянного тока.`,
      ],
    },
    type: {
      en: en.largeScale,
      geo: geo.largeScale,
      arm: "մեծ ծավալի",
      rus: "крупномасштабный",
    },
    imageSrc: require("../images/projects/Karmrashen.jpg"),
    otherImagesSrc: [
      require("../images/projects/singleProjects/Karmrashen-1.jpg"),
      require("../images/projects/singleProjects/Karmrashen-2.jpg"),
      require("../images/projects/singleProjects/Karmrashen-3.jpg"),
      require("../images/projects/singleProjects/Karmrashen-4.jpg"),
      require("../images/projects/singleProjects/Karmrashen-5.jpg"),
      require("../images/projects/singleProjects/Karmrashen-6.jpg"),
      require("../images/projects/singleProjects/Karmrashen-7.jpg"),
      require("../images/projects/singleProjects/Karmrashen-8.jpg"),
      require("../images/projects/singleProjects/Karmrashen-9.jpg"),
    ],
  },
];

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    getAllProjects: (state) => {
      return state;
    },
    getTypeProjects: (state, action) => {
      return state.filter((project) => project.type === action.payload);
    },
    getLargeScaleProjects: (state) => {
      return state.filter((project) => project.type === en.largeScale);
    },
    getCommercialProjects: (state) => {
      return state.filter((project) => project.type === en.commercial);
    },
    getResidentialProjects: (state) => {
      return state.filter((project) => project.type === en.residential);
    },
  },
});

export const {
  getAllProjects,
  getTypeProjects,
  getLargeScaleProjects,
  getCommercialProjects,
  getResidentialProjects,
} = projectsSlice.actions;

export default projectsSlice.reducer;
