import { useState, useEffect } from "react";
import validator from "email-validator"; // Import the email-validator package

// Custom hook to validate an email
const useEmailValidatorHook = (initialEmail = "") => {
  const [email, setEmail] = useState(initialEmail);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (email) {
      // Validate the email and update state
      if (validator.validate(email)) {
        setEmailIsValid(true);
        setError("");
      } else {
        setEmailIsValid(false);
        setError("Invalid email format");
      }
    } else if (email === "") {
      setEmailIsValid(false);
    } else if (email !== "") {
      setEmailIsValid(true); // Reset state if no email
      setError("");
    }
  }, [email]);

  return { email, setEmail, emailIsValid, error };
};

export default useEmailValidatorHook;
