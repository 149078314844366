import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronRight,
  faChevronLeft,
  faQuoteLeft,
} from "@fortawesome/free-solid-svg-icons";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import useScreenWidth from "../hooks/useScreenWidth";
import { useSelector } from "react-redux";

// Custom arrow components
const NextArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

const MultiItemCarousel = (prop) => {
  const { translations } = useSelector((state) => state.language);

  const language = prop.language;

  const screenWidth = useScreenWidth();

  const params = useParams();


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth < 490 ? 1 : screenWidth < 740 ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [sliderDots, setSliderDots] = useState(13);
  const [dotsInRow, setDotsInRow] = useState(13);

  const slickDotsEement = document.querySelector(".slick-slider");

  useEffect(() => {
    if (prop.projects) {
      setSliderDots(prop.projects.length);
      

     
      if (slickDotsEement) {
        // slickDotsEement.getBoundingClientRect().width
        setDotsInRow(
          Math.floor(slickDotsEement.getBoundingClientRect().width / 30)
        );
       
        const rows = Math.ceil(sliderDots / dotsInRow);
        
        slickDotsEement.classList.remove(`bottom--50`);
        slickDotsEement.classList.remove(`bottom--75`);
        slickDotsEement.classList.add(`bottom--${25 * rows}`);
      }
    }
  }, [prop.projects, sliderDots, dotsInRow, slickDotsEement, screenWidth]);

  return (
    <Slider {...settings}>
      {prop.projects &&
        prop.projects.filter((el) => params.projectId  || el.type.en !== 'LARGE SCALE').map((item, i) => (
          <div className="projects-item-container item-container p-2" key={i}>
            <div className="projects-item-inner-container item-inner-container">
              <Link
                to={`/projects/${item.name.en}`}
                className="projects-carousel-link"
              >
                <Image
                  src={item.imageSrc}
                  className={`project-img project-img-${i + 1}`}
                />
                <div className="projects-slide-mwh">
                  <p className="projects-slide-mwh-p">
                    {item.capacity} {translations.kw}
                  </p>
                </div>
                <div className="projects-body-container ">
                  <h6 className="projects-title">
                    {item.name[language]}
                  </h6>
                  <p className="projects-location">
                    {item.location[language]}
                  </p>
                  {/* <p className="projects-text">
                    {translations.annualElectricityProduction}: <span></span>
                  </p> */}
                  {/* <br /> */}
                  {/* <p className="projects-carousel-mwh">
                    - {item.annualElectricityProduction} {item.mwh[language]} -
                  </p> */}
                </div>
              </Link>
            </div>
          </div>
        ))}
      {prop.commentData &&
        prop.commentData.map((item, i) => (
          <div className="comment-item-container p-2" key={i}>
            <div className="item-inner-container comments-item-inner-container">
              <div className="comment-title-container">
                <h5>{item.name[language]}</h5>
              </div>
              <div className="comment-p-container">
                <p>
                  <span>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                  </span>{" "}
                  {item.text[language]}
                </p>
              </div>
              <div className="comment-company-name">
                <h6>{item.company[language]}</h6>
              </div>
            </div>
          </div>
        ))}
      {prop.employs &&
        prop.employs.map((item, i) => (
          <div className="employ-item-container p-2" key={i}>
            <div className="employ-inner-container">
              <div className="employ-img-container">
                <img src={item.img} alt="" className="employ-img" />
              </div>
              <div className="employ-name-linkdin-container">
                <div className="employ-name-position-container">
                  <h6>{item.name}</h6>
                  <p>{item.position}</p>
                </div>
                <div className="employ-linkdin">
                  <a
                    href={item.linkdinURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
    </Slider>
  );
};

export default MultiItemCarousel;
