import React, { useState } from "react";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import useEmailValidatorHook from "../hooks/useEmailValidatorHook";

const ContactsFormComponent = () => {
  const { translations } = useSelector((state) => state.language);
  const {calculator} = useSelector(state => state.calculator)


  const {  setEmail, emailIsValid } = useEmailValidatorHook("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerOrganization, setCustomerOrganization] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerCountryCode, setCustomerCountryCode] = useState("+995");
  const [customerComments, setCustomerComments] = useState("");
  const [loading, setLoading] = useState(false);

  const showErrorTooltipHelper = (isValid, errorField) => {
    if (!isValid) {
      document.querySelector(`.show-${errorField}-tooltip`).style.opacity = 1;
      document.querySelector(`.show-${errorField}-tooltip`).style.transform =
        "translateY(-90%)";
    } else {
      document.querySelector(`.show-${errorField}-tooltip`).style.transform =
        "translateY(-200%)";
      document.querySelector(`.show-${errorField}-tooltip`).style.opacity = 0;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setCustomerName(value);
        break;
      case "companyName":
        setCustomerOrganization(value);
        break;
      case "email":
        setEmail(value);
        setCustomerEmail(value);
        break;
      case "countryCode":
        setCustomerCountryCode(value);
        break;
      case "phoneNumber":
        setCustomerPhoneNumber(value);
        break;
      case "comments":
        setCustomerComments(value);
        break;
      default:
        break;
    }

    // Remove error class if input is corrected
    e.target.closest(".check-field")?.classList.remove("error-class");
    e.target.closest(".check-field")?.classList.remove("success-class");
    // const emailTooltipElement = e.target
    //   .closest(".check-field")
    //   ?.querySelector(`.show-email-tooltip`);
    // if (emailTooltipElement) {
    //   emailTooltipElement.style.opacity = 0;
    // }

    if (
      e.target.closest(".check-field")?.querySelector(`.show-email-tooltip`)
    ) {
      showErrorTooltipHelper(true, "email");
    }
    if (
      e.target
        .closest(".check-field")
        ?.querySelector(`.show-phonenumber-tooltip`)
    ) {
      showErrorTooltipHelper(true, "phonenumber");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the formData object
    const formData = {
      name: customerName,
      companyName: customerOrganization,
      email: customerEmail,
      countryCode: customerCountryCode,
      phoneNumber: customerPhoneNumber,
      comments: customerComments,
    };

    // Check for empty fields excluding companyName and countryCode
    const phonePattern = /^\+?[0-9\s-]{9,}$/;
    const newEmptyFields = Object.entries(formData)
      .filter(
        ([key, value]) =>
          key !== "companyName" && key !== "countryCode" && !value
      )
      .map(([key]) => key);

    document.querySelectorAll(".check-field").forEach((el) => {
      if (!el.className.includes("error-class")) {
        el.classList.add("success-class");
      } else {
        el.classList.remove("success-class");
      }
    });

    

    if (!emailIsValid && !newEmptyFields.includes("email")) {
      newEmptyFields.push("email");
    }

    if (
      !phonePattern.test(customerPhoneNumber) &&
      !newEmptyFields.includes("phoneNumber")
    ) {
      newEmptyFields.push("phoneNumber");
    }

    if (
      newEmptyFields.length === 0 &&
      emailIsValid &&
      phonePattern.test(customerPhoneNumber)
    ) {
      setLoading(true);
      fetch("https://backend-for-ohm-energy-test.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, ...calculator}),
      })
        .then((response) => response.text())
        .then((data) => {
          alert(data);
          if (data === "Email sent successfully") {
            setCustomerName("");
            setCustomerOrganization("");
            setCustomerEmail("");
            setCustomerCountryCode("+995");
            setCustomerPhoneNumber("");
            setCustomerComments("");
          }
          setLoading(false);
          document.querySelectorAll(".check-field").forEach((el) => {
            el.classList.remove("success-class");
          });
        })
        .catch((error) => {
          alert("Error sending email");
          
          setLoading(false);
        });
    } else {
      // Show error class for empty fields
      newEmptyFields.forEach((field) => {
        const inputElement = document
          .querySelector(`[name="${field}"]`)
          .closest(".check-field");
        if (inputElement) {
          inputElement.classList.add("error-class");
        }
      });

      showErrorTooltipHelper(emailIsValid, "email");

      // Test the input value against the regular expression pattern

      showErrorTooltipHelper(
        phonePattern.test(customerPhoneNumber),
        "phonenumber"
      );
      // Optionally, you can set an error state or provide feedback
    }
    
  };

  return (
    <div className="contact-page-forms-inner-container">
      <Form onSubmit={handleSubmit} noValidate className="contact-page-form">
        <div className="contact-page-form-name-company-name-input-container">
          <Form.Floating className="mb-4 contact-page-from-input contact-page-from-name-input check-field">
            <Form.Control
              id="name-input"
              type="text"
              name="name"
              placeholder="Name"
              value={customerName}
              onChange={handleChange}
              autoComplete="name"
              required
            />
            <label htmlFor="name-input">{translations.name}</label>
          </Form.Floating>
          <Form.Floating className="mb-4 contact-page-from-input contact-page-from-company-name-input">
            <Form.Control
              id="company-name-input"
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={customerOrganization}
              onChange={handleChange}
              autoComplete="organization"
            />
            <label htmlFor="company-name-input">
              {translations.companyName}
            </label>
          </Form.Floating>
        </div>
        <div className="contact-page-form-email-phone-number-input-container">
          <Form.Floating className="mb-4 contact-page-from-input contact-page-from-email-input check-field">
            <Form.Control
              id="email-input"
              type="email"
              name="email"
              placeholder="name@example.com"
              value={customerEmail}
              autoComplete="email"
              onChange={handleChange}
              className="h-100"
              required
            />
            <label htmlFor="email-input">{translations.emailAdrress}</label>
            <div className="show-email-tooltip">
              <p>{translations.emailValidity}</p>
            </div>
          </Form.Floating>
          <div className="mb-4 form-floating-container contact-page-form-number-container check-field">
            <Form.Floating className="contact-page-from-input contact-page-form-number-start-input-container">
              <Form.Select
                aria-label="Country Code"
                id="phone-country-code"
                name="countryCode"
                className="country-code-select"
                value={customerCountryCode}
                onChange={handleChange}
              >
                <option value="+995">+995</option>
                <option value="+324">+324</option>
                <option value="+342">+342</option>
              </Form.Select>
              <label htmlFor="phone-country-code">
                {translations.countryCode}
              </label>
            </Form.Floating>
            <Form.Floating className="contact-page-from-input contact-page-form-number-input-container">
              <Form.Control
                id="phone-number-input"
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={customerPhoneNumber}
                onChange={handleChange}
                pattern="[0-9]{9,}"
                minLength="9"
                required
              />
              <label htmlFor="phone-number-input">
                {translations.phoneNumber}
              </label>
            </Form.Floating>
            <div className="show-phonenumber-tooltip">
              <p>{translations.phoneNumberValidity}</p>
            </div>
          </div>
        </div>
        <FloatingLabel
          controlId="floatingTextarea2"
          label={<p className="comments-label-p">{translations.comments}</p>}
          className={`contact-page-from-input check-field contact-page-from-comment-input ${
            customerComments ? "leave-comment-label" : ""
          }`}
        >
          <Form.Control
            as="textarea"
            name="comments"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            value={customerComments}
            onChange={handleChange}
            required
          />
        </FloatingLabel>
        <Button
          className="contact-page-submit-button"
          type="submit"
          disabled={loading}
        >
          {loading ? "Loading..." : translations.submit}
        </Button>
      </Form>
    </div>
  );
};

export default ContactsFormComponent;
