// import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import useScreenWidth from "../hooks/useScreenWidth";
// import useScreenWidth from "../hooks/useScreenWidth";

const Partners = () => {
  const partners = [
    {
      name: "SMA Solar Technology AG",
      logo: require("../images/partners/partner-1.png"),
    },
    {
      name: "Solar Technologies, Inc.",
      logo: require("../images/partners/partner-2.png"),
    },
    {
      name: "KACO new energy GmbH",
      logo: require("../images/partners/partner-3.png"),
    },
    { name: "Jäspi Solar", logo: require("../images/partners/partner-4.png") },
    {
      name: "UAB Solet Technics",
      logo: require("../images/partners/partner-5.png"),
    },
    {
      name: "Stäubli International AG",
      logo: require("../images/partners/partner-6.png"),
    },
    {
      name: "AEG",
      logo: require("../images/partners/partner-11.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-8.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-9.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-10.png"),
    },
    {
      name: "Weidmüller Interface GmbH & Co. KG",
      logo: require("../images/partners/partner-7.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-12.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-13.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-14.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-15.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-16.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-17.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-18.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-19.png"),
    },
    {
      name: "ESSD (Energy Saving Solutions & Design)",
      logo: require("../images/partners/partner-20.png"),
    },
  ];

  const width = useScreenWidth();

  // const partnersNum = partners.length;
  // const screenWidth = useScreenWidth();

  const partnersForsmallDevices = (
    <div>
      <Row className="d-flex justify-content-between partners-row-1 partners-row">
        {partners
          .filter((el, ind) => ind < 5)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 1
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 1
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 1}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-2 partners-row">
        {partners
          .filter((el, ind) => ind >= 5 && ind < 10)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 6
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 6
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 6}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-3 partners-row pe-1">
        {partners
          .filter((el, ind) => ind >= 10 && ind < 15)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 11
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 11
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 11}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-4 partners-row">
        {partners
          .filter((el, ind) => ind >= 15)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 16
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 16
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 16}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
    </div>
  );

  const partnersForLargeDevices = (
    <div>
      <Row className="d-flex justify-content-between partners-row-1 partners-row">
        {partners
          .filter((el, ind) => ind < 10)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 1
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 1
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 1}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-2 partners-row pe-1">
        {partners
          .filter((el, ind) => ind >= 10)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 11
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 11
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 11}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      {/* {part.name} */}
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
    </div>
  );

  return (
    <Container
      fluid
      className="py-3 px-0 d-flex flex-column align-items-center partners-container"
    >
      {width < 1011 ? partnersForsmallDevices : partnersForLargeDevices}
      {/* <Row className="d-flex justify-content-between partners-row-1 partners-row">
        {partners
          .filter((el, ind) => ind < 7)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 1
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 1
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 1}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                     
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-2 partners-row pe-1">
        {partners
          .filter((el, ind) => ind >= 7 && ind < 14)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 8
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 8
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 8}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                      
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row>
      <Row className="d-flex justify-content-between partners-row-3 partners-row">
        {partners
          .filter((el, ind) => ind >= 14)
          .map((part, i) => (
            <div
              key={i}
              className={`partners-col-container partners-col-container-${
                i + 15
              } px-0 rounded-0 align-content-center`}
            >
              <Card
                className={`position-relative partners-card rounded-0 border-0 card-${
                  i + 15
                }`}
              >
                {
                  <Card.Img
                    variant="top"
                    src={part.logo}
                    className={`card-img card-img-${i + 15}`}
                  />
                }
                <Card.Body className="card-link-container px-0 py-0">
                  <div className="card-inner-body">
                    <Card.Link className="partners-card-link" href="#">
                     
                    </Card.Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Row> */}
    </Container>
  );
};

export default Partners;
