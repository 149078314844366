const rus = {
  ohmEnergy: "OHM ENERGY",
  contactUs: "контакт",
  aboutUs: "О НАС",
  projects: "ПРОЕКТЫ",
  partners: "ПАРТНЕРЫ",
  solarCalculator: "СОЛНЕЧНЫЙ КАЛЬКУЛЯТОР",
  allProjects: "Все проекты",
  countries: "Страны",
  satisfiedCustomers: "Довольные клиенты",
  mWhProduced: "Производство mWh",
  solarPowerPlantCalculator: "Калькулятор солнечной электростанции",
  calculatorDescription: `Используйте наш калькулятор, чтобы узнать необходимую мощность системы, стоимость и другие необходимые вам данные. Просто введите ваши ежемесячные расходы на электроэнергию в грузинских лари (GEL), и калькулятор выполнит все необходимые вычисления за вас.`,
  selectRegion: "Выберите свой регион",
  tbilisi: "Тбилиси",
  adjara: "Аджара",
  racha: "Рача",
  kakheti: "Кахети",
  imereti: "Имерети",
  selectPlaceForPanels: "Выберите место для панелей",
  onGround: "На земле",
  slopingRoof: "Наклонная крыша",
  straightRoof: "Прямая крыша",
  monthlyCost: "Ежемесячная стоимость",
  enterMonthlyCost:
    "Введите, сколько вы платите за электроэнергию каждый месяц, в рублях",
  currency: "RUB",
  calculate: "Рассчитать",
  calculation: "Расчет",
  forPerson: "для человека",
  forCompany: "для компании",
  chargePerKWh: "Стоимость за 1 кВт·ч электроэнергии:",
  installedPower: "Установленная мощность установки в киловаттах: кВт·ч.",
  monthlyConsumption: "Ежемесячное потребление электроэнергии в кВт·ч: кВт·ч.",
  requiredArea: "Требуемая площадь: кв. м",
  projectCost: "Стоимость проекта:",
  customersComments: "КОММЕНТАРИИ КЛИЕНТОВ",
  siteMap: "Карта сайта",
  address: "Адрес",
  addressStr: "Улица Палиашвили, 11а",
  addressMapLocation: "Тбилиси, Грузия",
  workingHours: "Рабочие часы",
  workingHoursDetail: "Пн - Пт, 10:00 - 19:00",
  contact: "Контакт",
  contactNumber: "+995 32 280 00 33",
  contactEmail: "info@ohmenergy.ge",
  followUs: "Подписывайтесь на нас",
  copyright: "© OHM ENERGY SOLUTIONS 2024",
  ourProjects: "Наши проекты",
  largeScale: "крупный масштаб",
  commercial: 'коммерческий',
  residential: `Жилой`,
  projectsLowerCase: "проекты",
  description: "Описание",
  capicity: "Мощность",
  location: "Расположение",
  SolarModule: "Солнечный модуль",
  inverter: "Инвертор",
  annualElectricityProduction: "Годовое производство электроэнергии",
  ourPartners: `ՄԵՐ ԳՈՐԾԸՆԿԵՐՆԵՐԸ`,
  partnersCanadianSolarDescription: `AEG soon`,
  partnersAeSolarDescription: `AE solar soon`,
  partnersAstroDescription: `Astro Energy soon`,
  partnersHuaweiDescription: `Huawei soon`,
  partnersSmaDescription: `SMA soon`,
  partnersKbeDescription: `Kbe soon`,
  partnersSchneiderElectricDescription: `shneiderelectric soon`,
  partnersHikraDescription: `Hikra soon`,
  partnersStaubliDescription: `Stäubli — поставщик решений в области мехатроники с тремя основными направлениями деятельности: соединители, робототехника и текстильная промышленность. Stäubli — это международная группа, которая в настоящее время работает в 29 странах и имеет агентов в 50 странах на четырех континентах. Сотрудники компании, насчитывающие 5500 человек по всему миру, разделяют стремление сотрудничать с клиентами практически во всех отраслях, чтобы предоставлять комплексные решения с долгосрочной поддержкой.`,
  partnersKiracDescription: `kirac re soon`,
  partnersHisDescription: `HIS re soon`,
  partnersSuntreeDescription: `SUNTREE re soon`,
  partnersAbbDescription: `ABB soon`,
  partnersK2Description: `K2 re soon`,
  partnersMunichreDescription: `Munich re soon`,
  partnersArielreDescription: `ArielRe soon`, 
  partnersTuvRheinlandDescription: `TÜV Rheinland выступает за безопасность и качество практически во всех сферах бизнеса и жизни. Основанная более 150 лет назад, компания является одним из ведущих мировых поставщиков услуг по тестированию со штатом более 20 870 сотрудников и годовым доходом около 2,3 миллиарда евро. Высококвалифицированные специалисты TÜV Rheinland тестируют технические системы и продукцию по всему миру, поддерживают инновации в технологиях и бизнесе, обучают людей многочисленным профессиям и сертифицируют системы управления в соответствии с международными стандартами. При этом независимые эксперты вызывают доверие к продуктам, а также к процессам в глобальных цепочках создания стоимости и потоках товаров. С 2006 года TÜV Rheinland является членом Глобального договора ООН по содействию устойчивому развитию и борьбе с коррупцией.`,
  partnersUnisonDescription: `Unoson soon`,
  partnersGedfDescription: `GEDF soon`,
  aboutUsDescription: `OHM ENERGY Компания OHM ENERGY была основана в 2016 году как поставщик услуг проектирования и строительства. Компания помогает клиентам принимать качественные решения в сфере энергетики и является одной из ведущих компаний в регионе. Организация реализовала в общей сложности более 80 мегаватт бытовых, коммерческих и масштабных проектов в Грузии, Армении и Узбекистане.`,
  vision:  `НАШ ВЗГЛЯД`,
  aboutUsVision: `Наше видение состоит в том, чтобы интегрировать сектор возобновляемых источников энергии в экономику с помощью высокостандартных методологий, услуг и оборудования, а также эффективных, стабильных и ориентированных на будущее решений.`,
  irakliUbilava: `Ираклий Убилава`,
  irakliBabunashvili: `Ираклий Бабунашвили`,
  davitMarkhulia: `Дэвид постится`,
  davitBardavelidze: `Давид Бардавелидзе`,
  operationsManager: 'менеджер по операциям',
  salesManager: `менеджер по продажам`,
  managingPartner: 'управляющий партнер',
  CustomersCommentsTitle:`КОММЕНТАРИИ КЛИЕНТОВ`,
  contactPage: 'КОНТАКТЫ',
  name: 'имя',
  companyName: `название компании`,
  emailAdrress:  `электронная почта`,
  countryCode: `код страны`,
  phoneNumber: `номер телефона`,
  sendUsEmail: `отправьте нам письмо`,
  comments: 'Оставьте комментарий здесь',
  submit: 'отправлять',


};
export default rus;
