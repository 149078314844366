import { useEffect, useState } from "react";

const useCurrencyhook = () => {
  const [usdToGel, setUsdToGel] = useState(1);

  useEffect(() => {
    const currencyFetch = async () => {
      try {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
        );
        const data = await response.json();
        setUsdToGel(data.usd.gel)

      } catch (error) {
        return error;
      }
    };

    currencyFetch()
  }, []);
    return usdToGel
};

export default useCurrencyhook;
