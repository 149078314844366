import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  calculator: null,
};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    setCalcData: (state, action) => {
      state.calculator = action.payload; // Set the calculator data from action payload
    },
  },
});

export const { setCalcData } = calculatorSlice.actions;

export default calculatorSlice.reducer;
