import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import { geo, arm, usa, rus, logo } from "../images/flags/flags";
import { setLanguage } from "../data/langSlice";
import useScreenWidth from "../hooks/useScreenWidth";

const NavbarComponent = () => {
  const { translations, language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedLangFlag, setSelectedLangFlag] = useState({
    langFlag:
      language === "geo"
        ? geo
        : language === "en"
        ? usa
        : language === "arm"
        ? arm
        : rus,
    lang: language,
  });

  const langArrayObjs = [
    { langFlag: usa, lang: "en" },
    { langFlag: geo, lang: "geo" },
    // { langFlag: arm, lang: "arm" },
    // { langFlag: rus, lang: "rus" },
  ];

  const screenWidth = useScreenWidth();

  const [dropdonwOpen, setDropdownOpne] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = (isOpen) => {
    setDropdownOpne(!dropdonwOpen);
  };

  useEffect(() => {
    dispatch(setLanguage(selectedLangFlag.lang));
    if (language === "arm") {
      // document.querySelector('.lang-toggle-container').style.width = '84%'
    } else {
      // document.querySelector('.lang-toggle-container').style.width = '82%'
    }
  });

  const selectLanguage = (e, el) => {
    setSelectedLangFlag(el);
    dispatch(setLanguage(el.lang));
  };

  useEffect(() => {
    const handleHashChange = () => {
      const yOffset = -70; // Adjust this value to match your navbar height
      const element = document.getElementById(
        window.location.hash.substring(1)
      );
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    // Scroll to hash on initial load
    if (location.hash) {
      handleHashChange();
    }

    // Add event listener for hashchange
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [location]);


  // remove calculator calcuate price window
  const handleClick = (e) => {
    const calcContainer = document.querySelector(".calc-count-price-container");
   
    if (e.target.closest(".calc-count-price-inner-container") === null) {
      calcContainer.style.display = "none";
      document.querySelector('.App').style.filter = 'blur(0px)'

    }
  };

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary py-0 navbar-container mb-0 pb-0 px-0"
      fixed="top"
      expanded={screenWidth < 992 ? expanded : false}
      onClick={handleClick}
    >
      <Container fluid className="h-100 navbar-inner-container">
        <Navbar.Brand as={Link} to="/" className="h-100 me-0 py-0">
          <div className="logo-container">{logo}</div>
        </Navbar.Brand>
        <div className="lang-toggle-container">
          <div className="contact-menu contact-menu-sm d-none d-sm-block">
            <Nav.Link
              as={Link}
              to="/contact"
              className="me-0 py-2 px-3 text-dark contact-us-button text-center"
            >
              {translations.contactUs}
            </Nav.Link>
            <div className="contact-button-after"></div>
          </div>
          <div className="lang-container">
            <NavDropdown
              title={selectedLangFlag.langFlag}
              id="basic-nav-dropdown"
              className={`flags-drop-down-item ps-0 ${
                dropdonwOpen ? "fadein" : "fadeout"
              }`}
              onToggle={handleToggle}
            >
              {langArrayObjs
                .filter((el, i) => el.lang !== selectedLangFlag.lang)
                .map((el, i) => (
                  <div key={i}>
                    <NavDropdown.Divider className="w-100" />
                    <NavDropdown.Item
                      className="w-100  px-1"
                      value={el.lang}
                      onClick={(e) => selectLanguage(e, el)}
                    >
                      {el.langFlag}
                    </NavDropdown.Item>
                  </div>
                ))}
              <div className="arm-language pb-1">
                <NavDropdown.Divider className="w-100 pt-1" />

                <a
                  className="w-100 h-100 px-1"
                  href="https://www.ohmenergy.am/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {arm}
                </a>
              </div>
            </NavDropdown>
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            className="menu-toggle-container px-xxl-0"
          >
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-md`}
              className="border-0 close-open-menu"
            />
            <Navbar.Offcanvas
              scroll={true}
              id={`offcanvasNavbar-expand-sm`}
              aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
              placement="end"
              className="offcanvas-menu "
              onClick={() => setExpanded(!expanded)}
            >
              <Offcanvas.Header
                closeButton
                className="offcanvas-header-background-color"
              >
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-sm`}
                  className="w-100 text-center"
                >
                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className="w-75 mx-auto px-0 rounded-0 offcanvas-contact"
                  >
                    {translations.contactUs}
                  </Nav.Link>
                  <div className="offcanvas-title-after"></div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="px-0 px-lg-0 offcanvas-body-background-color">
                <Nav className="offcanvas-full-navbar flex-grow-1 offcanvas-links-container">
                  <Nav.Link as={Link} to="/about-us">
                    {translations.aboutUs}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/projects">
                    {translations.projects}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/partners">
                    {translations.partners}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/#calculator">
                    {translations.solarCalculator}
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
