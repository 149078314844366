const arm = {
  ohmEnergy: "OHM ENERGY",
  contactUs: "դիմեք մեզ",
  allProjects: "Բոլոր Նախագծերը",
  countries: "Երկրներ",
  satisfiedCustomers: "Գոհունակ հաճախորդներ",
  mWhProduced: "mWh Արտադրված",
  solarPowerPlantCalculator: "Արևային էլեկտրակայանների հաշվիչ",
  calculatorDescription: `Օգտագործեք մեր հաշվիչը՝ պարզելու համակարգի պահանջվող հզորությունը, արժեքը և ձեզ անհրաժեշտ այլ մանրամասներ: Պարզապես մուտքագրեք ձեր ամսական էլեկտրաէներգիայի ծախսը վրացական լարիով (Լարի), և հաշվիչը կկատարի ձեզ համար անհրաժեշտ բոլոր հաշվարկները`,
  selectRegion: "Ընտրիր քո տարածաշրջանը",
  tbilisi: "Թբիլիսի",
  selectPlaceForPanels: "Ընտրեք տեղ վահանակների համար",
  onGround: "Գետնի վրա",
  slopingRoof: "թեք տանիք",
  straightRoof: "Ուղիղ տանիք",
  monthlyCost: "Ամսական արժեքը",
  enterMonthlyCost:
    "Մուտքագրեք, թե ամեն ամիս որքան եք վճարում էլեկտրաէներգիայի համար՝ լարիով",
  currency: "Dram",
  calculation: "Հաշվարկ՝",
  forPerson: "անձի համար",
  chargePerKWh: "1 կՎտ/ժ էլեկտրաէներգիայի համար վճար՝ լարի",
  installedPower: "Կայանի տեղադրված հզորությունը կիլովատներով՝ կՎտժ",
  monthlyConsumption: "Էլեկտրաէներգիայի ամսական սպառումը կՎտ*ժամում՝ կՎտ*ժ",
  requiredArea: `Պահանջվող տարածք՝ քմ`,
  projectCost: "Ծրագրի արժեքը՝",
  customersComments: "ՀԱՃԱԽՈՐԴՆԵՐԻ ՄԵԿՆԱԲԱՆՈՒԹՅՈՒՆՆԵՐ",
  siteMap: "Կայքի քարտեզ",
  aboutUs: "ՄԵՐ ՄԱՍԻՆ",
  projects: "ԾՐԱԳՐԵՐ",
  ourProjects: `ՄԵՐ ՆԱԽԱԳԾԵՐԸ`,
  blog: "բլոգ",
  partners: "ԳՈՐԾԸՆԿԵՐՆԵՐ",
  solarCalculator: "ԱՐԵՎԱՅԻՆ ՀԱՇՎԻՉ",
  address: "Հասցե",
  addressStr: "Ուլիցա Փալիաշվիլի, 11ա",
  addressMapLocation: "Թբիլիսի, Վրաստան",
  workingHours: "Աշխատանքային ժամեր",
  workingHoursDetail: "Երկ - Ուրբաթ, 10:00 - 19:00",
  contact: "Կապ",
  contactNumber: "+995 32 280 00 33",
  contactEmail: "info@ohmenergy.ge",
  followUs: "Հետևիր մեզ",
  copyright: "© OHM ENERGY SOLUTIONS 2024",
  largeScale: "մեծ մասշտաբով",
  commercial:  'կոմերցիոն',
  residential: `բնակելի`,
  projectsLowerCase: "նախագծեր",
  description: "Նկարագրություն",
  capicity: "Կարողություն",
  location: "Գտնվելու վայրը",
  SolarModule: "Արևային մոդուլ",
  inverter: "ինվերտոր",
  annualElectricityProduction: "Էլեկտրաէներգիայի տարեկան արտադրություն",
  ourPartners: `НАШИ ПАРТНЕРЫ`,
  partnersCanadianSolarDescription: `AEG soon`,
  partnersAeSolarDescription: `AE solar soon`,
  partnersAstroDescription: `Astro Energy soon`,
  partnersHuaweiDescription: `Huawei soon`,
  partnersSmaDescription: `SMA soon`,
  partnersKbeDescription: `Kbe soon`,
  partnersSchneiderElectricDescription: `shneiderelectric soon`,
  partnersHikraDescription: `Hikra soon`,
  partnersStaubliDescription: `Stäubli-ն մեխատրոնիկայի լուծումների մատակարար է, որն ունի երեք հիմնական գործունեություն՝ միակցիչներ, ռոբոտաշինություն և տեքստիլ: Stäubli-ն միջազգային խումբ է, որը ներկայումս գործում է 29 երկրներում, գործակալներ ունի չորս մայրցամաքների 50 երկրներում: Համաշխարհային 5500 աշխատուժը կիսում է պարտավորվածությունը՝ համագործակցելու հաճախորդների հետ գրեթե բոլոր ոլորտներում՝ երկարաժամկետ աջակցությամբ համապարփակ լուծումներ տրամադրելու համար:`,
  partnersKiracDescription: `kirac soon`,
  partnersHisDescription: `HIS re soon`,
  partnersSuntreeDescription: `SUNTREE soon`,
  partnersAbbDescription: `ABB soon`,
  partnersK2Description: `K2 re soon`,
  partnersMunichreDescription: `Munich re soon`,
  partnersArielreDescription: `ArielRe soon`, 
  partnersTuvRheinlandDescription: `TÜV Rheinland-ը նշանակում է անվտանգություն և որակ բիզնեսի և կյանքի գրեթե բոլոր ոլորտներում: Ավելի քան 150 տարի առաջ հիմնադրված ընկերությունն աշխարհի առաջատար թեստավորման ծառայություններ մատուցողներից մեկն է՝ ավելի քան 20,870 աշխատակիցներով և տարեկան մոտ 2,3 միլիարդ եվրո եկամուտով: TÜV Rheinland-ի բարձր որակավորում ունեցող փորձագետները փորձարկում են տեխնիկական համակարգեր և ապրանքներ ամբողջ աշխարհում, աջակցում են նորարարություններին տեխնոլոգիայի և բիզնեսում, վերապատրաստում են մարդկանց բազմաթիվ մասնագիտություններով և հավաստագրում կառավարման համակարգերը միջազգային չափանիշներին համապատասխան: Դրանով անկախ փորձագետները վստահություն են առաջացնում ապրանքների, ինչպես նաև գործընթացների նկատմամբ համաշխարհային արժեքի ավելացման շղթաներում և ապրանքների հոսքում: 2006 թվականից TÜV Rheinland-ը ՄԱԿ-ի Գլոբալ համաձայնագրի անդամ է՝ կայունությունը խթանելու և կոռուպցիայի դեմ պայքարելու նպատակով:`,
  partnersUnisonDescription: `Unison soon`,
  partnersGedfDescription: `GEDF soon`,
  partnersGeffDescription: `GEFF soon`,
  aboutUsDescription: `OHM ENERGY OHM ENERGY հիմնադրվել է 2016 թվականին՝ որպես նախագծային և շինարարական ծառայություններ մատուցող։ Ընկերությունն օգնում է հաճախորդներին որակյալ որոշումներ կայացնել էներգետիկայի ոլորտում և հանդիսանում է տարածաշրջանի առաջատար ընկերություններից մեկը։ Կազմակերպությունն ընդհանուր առմամբ իրականացրել է ավելի քան 80 մեգավատ հզորությամբ կենցաղային, առևտրային և լայնածավալ նախագծեր Վրաստանում, Հայաստանում և Ուզբեկստանում։`,
  vision: `ՄԵՐ ՏԵՍԼԻՔԸ`,
  aboutUsVision: `Մեր տեսլականն է ինտեգրել վերականգնվող էներգիայի ոլորտը տնտեսության մեջ՝ բարձր ստանդարտների մեթոդոլոգիաներով, ծառայություններով և սարքավորումներով արդյունավետ, կայուն և ապագային միտված լուծումներով:`,
  irakliUbilava: `Իրակլի Ուբիլավա`,
  irakliBabunashvili: `Իրակլի Բաբունաշվիլի`,
  davitMarkhulia: `Դավիթը ծոմ է պահում`,
  davitBardavelidze: `Դավիթ Բարդավելիձե`,
  operationsManager: "գործառնությունների մենեջեր",
  salesManager: `վաճառքի մենեջեր`,
  managingPartner: "կառավարող գործընկեր",
  CostumersCommentsTitle:`ՀԱՃԱԽՈՐԴՆԵՐԻ ՄԵԿՆԱԲԱՆՈՒԹՅՈՒՆԸ`,
  contactPage: 'ԿՈՆՏԱԿՏԻ ԷՋ',
  name: 'անուն',
  companyName: `ընկերության անվանումը`,
  emailAdrress:  `էլ․ հասցե`,
  countryCode: `երկրի կոդը`,
  phoneNumber: `հեռախոսահամար`,
  sendUsEmail: `ուղարկել մեզ էլ`,
  comments: 'Թողեք մեկնաբանություն այստեղ',
  submit: 'ուղարկել',


};
export default arm;
