import React from "react";
import { useSelector } from "react-redux";

const TableComponent = ({ project, language }) => {
  const {translations} = useSelector(state => state.language);  return (
    <div className="table">
      <div className="inner-table">
        <div className="table-row table-row-1">
          <div className="thead"><h6>{translations.capicity}:</h6></div>
          <div className="tbody">{project.capacity} {translations.kw}</div>
        </div>
        <div className="table-row">
          <div className="thead"><h6>{translations.location}:</h6></div>
          <div className="tbody">{project.location[language]}</div>
        </div>
        <div className="table-row">
          <div className="thead"><h6>{translations.SolarModule}:</h6></div>
          <div className="tbody">{project.solarModules[language]}</div>
        </div>
        <div className="table-row">
          <div className="thead"><h6>{translations.inverter}:</h6></div>
          <div className="tbody">{project.inverter[language]}</div>
        </div>
        <div className="table-row">
          <div className="thead"><h6>{translations.annualElectricityProduction}։</h6></div>
          <div className="tbody">{project.annualElectricityProduction} {translations.mwh}</div>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
