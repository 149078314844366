import { configureStore } from "@reduxjs/toolkit";

import projectsReducer from "./projectsSlice";

import partnersReducer from "./partnersSlice";

import languageReducer from "./langSlice";

import calculatorReducer from './calculatorSlice';

const store = configureStore({
  reducer: {
    projects: projectsReducer,
    partners: partnersReducer,
    language: languageReducer,
    calculator: calculatorReducer,
  },
});

export default store;
