import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const PartnersPage = () => {
  const partners = useSelector((state) => state.partners);
  const { language } = useSelector((state) => state.language);
  const { translations } = useSelector((state) => state.language);

  return (
    <Container fluid className="partners-page-container">
      <h4 className="partners-page-title">{translations.ourPartners}</h4>
      {partners &&
        partners.map((partner, i) => (
          <Row className="partners-page-row" key={i}>
            <Col sm={3} className="partners-page-col">
              <div className="partners-page-img-container">
                <div
                  className={`partners-page-img-inner-container partners-page-img-inner-container-${i}`}
                >
                  {
                    <Image
                      src={partner.logo}
                      className={`partners-page-img partners-page-img-${i}`}
                    />
                  }
                </div>
              </div>
            </Col>
            <Col sm={9} className="partners-page-text-col">
              <div className="partners-page-text-container">
                <div className="partners-page-title-container">
                  <h5>{partner.name}</h5>
                </div>
                <div className="partners-page-paragraph-container">
                 {+partner.paragraph[language].length < 4 ? partner.paragraph[language].map((line, index) => (
                  <p key={index} className={`partner-page-text-${index}`}>{line}</p>
                 )) :<p>{partner.paragraph[language]}</p>
                }
                
                </div>
              </div>
              <div className="partners-page-flag-name-container">
                <div className="partners-page-flag-container">
                  <img src={partner.flag} alt="" />
                </div>
                <div className="partners-page-partner-name">
                  <a
                    href={partner.page}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="partners-page-partner-name-inner"
                  >
                    <div className="partners-page-globe-container">
                      <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <h5>{partner.name}</h5>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ))}
    </Container>
  );
};

export default PartnersPage;
