import React from "react";
// import { Link } from "react-router-dom";
import { Container, Nav, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import { logo } from "../images/flags/flags";
import "../footer.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FooterComponent = () => {
  const { translations } = useSelector((state) => state.language);
  return (
    <Container
      fluid
      className="footer d-flex flex-column justify-content-center align-center px-0"
    >
      <Container fluid className="footer-inner-container">
        <Row className=" border-bottom ">
          <div className="footer-menu-info-container ">
            <div className="footer-site-map-container">
              <div className="footer-site-map-title-container">
                <h4>{translations.siteMap}</h4>
                <hr />
              </div>
              <div className="footer-site-map-logo-menu-container">
                {/* <div className="footer-site-map-logo-container">
                <div className="footer-site-map-logo-container">
                  <div className="footer-site-map-logo-inner-container pt-0">
                    {logo}
                  </div>
                </div>
              </div> */}
                <div className="footer-site-map-menu-container">
                  <Nav className="footer-nav-inner">
                    <Nav.Link as={Link} to="/about-us">
                      {translations.aboutUs}
                    </Nav.Link>
                    {/* <NavDropdown title="SERViCES" id="basic-nav-dropdown" className="d-inline-flex p-relative">
                    <Dropdown.Item>Solar Install</Dropdown.Item>
                    <Dropdown.Item>Modern Heating</Dropdown.Item>
                  </NavDropdown> */}
                    {/*<Accordion
                      className="footer-accordion-container"
                      // defaultActiveKey="0"
                      flush
                    >
                       <Accordion.Item eventKey="0">
                        <Accordion.Header className="footer-accordion">
                        {translations.projects}
                          
                        </Accordion.Header>
                        <Accordion.Body className="py-0 ps-2 pe-0 d-flex flex-column">
                          <Nav.Link className=" pe-0 footer-large-scale">
                            <p>LARGE SCALE</p>
                          </Nav.Link>
                          <Nav.Link className=" pe-0">
                            <p>COMMERCIAL</p>
                          </Nav.Link>
                          <Nav.Link className=" pe-0">
                            <p>RESIDENTIALL</p>
                          </Nav.Link>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Nav.Link>
                      <p>BLOG</p>
                    </Nav.Link> */}
                    <Nav.Link as={Link} to="/projects">
                      {translations.projects}
                    </Nav.Link>
                    <Nav.Link as={Link} to="/partners">
                      {translations.partners}
                    </Nav.Link>
                    <Nav.Link as={Link} to="/#calculator">
                      {translations.solarCalculator}
                    </Nav.Link>
                  </Nav>
                </div>
              </div>
            </div>
            <div className="footer-address-working-hours-menu-container">
              <div className="footer-site-map-title-container">
                <h4>{translations.address}</h4>
                <hr />
              </div>
              <div className="footer-site-map-p-container footer-site-map-address-text-container">
                <p>{translations.addressStr}</p>
                <p>{translations.addressMapLocation}</p>
              </div>
              <div className="footer-working-hours-menu-container">
                <div className="footer-site-map-title-container">
                  <h4>{translations.workingHours}</h4>
                  <hr />
                </div>
                <div className="footer-site-map-p-container footer-site-map-working-hours-text-container">
                  <p>{translations.workingHoursDetail}</p>
                </div>
              </div>
            </div>
            <div className="footer-contact-menu-container">
              <div className="footer-site-map-title-container">
                <h4>{translations.contact}</h4>
                <hr />
              </div>
              <div className="footer-site-map-p-container footer-site-map-contact-text-container">
                <div className="footer-site-map-contact-info-container footer-site-map-contact-number-container">
                  <FontAwesomeIcon icon={faPhone} />
                  <p className="ps-3 text-center">+995 32 280 00 33</p>
                </div>
                <div className="footer-site-map-contact-info-container footer-site-map-contact-mail-container">
                  <FontAwesomeIcon icon={faEnvelope} />
                  <p className="ps-3 text-center">info@ohmenergy.ge</p>
                </div>
              </div>
            </div>
            <div className="footer-social-media-container">
              <div className="text-center pb-2">
                <h3>{translations.followUs}</h3>
              </div>
              <div className="follow-us">
                <div className="footer-icons d-flex justify-content-center gap-3">
                  <div className="footer-icon-inner-container">
                    <a
                      href="https://www.facebook.com/ohmgeorgia"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        className="icon-size"
                        size="2x"
                      />
                    </a>
                  </div>
                  <div className="footer-icon-inner-container">
                    <a
                      href="https://www.instagram.com/ohmenergy.ge/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        className="icon-size"
                        size="2x"
                      />
                    </a>
                  </div>
                  <div className="footer-icon-inner-container">
                    <a
                      href="https://www.linkedin.com/company/ohm-energy-georgia-%E1%83%9D%E1%83%9B-%E1%83%94%E1%83%9C%E1%83%94%E1%83%A0%E1%83%AF%E1%83%98-%E1%83%A1%E1%83%90%E1%83%A5%E1%83%90%E1%83%A0%E1%83%97%E1%83%95%E1%83%94%E1%83%9A%E1%83%9D/mycompany/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        className="icon-size"
                        size="2x"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-site-map-"></div> */}
          </div>
        </Row>
        <Row className="pt-4">
          <p className="text-center mb-0">
            © <span className="text-warning ">OHM ENERGY </span> SOLUTIONS
            2024
          </p>
        </Row>
      </Container>
    </Container>
  );
};

export default FooterComponent;
