import React from "react";
import { useSelector } from "react-redux";
import {Button} from "react-bootstrap";
import CalcCauntPrice from "./CalcCauntPrice";

const CalcComponent = ({ localCalcData, stationArea, stationPower }) => {
  const {translations} = useSelector(state => state.language);
  
  const handleSubmit = (e) => {
    const calcContainer = document.querySelector('.calc-count-price-container');

    if(localCalcData && stationPower) {
      if(calcContainer) {
        calcContainer.style.display = 'flex';
        document.querySelector('.App').style.filter = 'blur(3px)';
      }
    }
  }

 
  
  const forPerson = (
    <div>
      {" "}
      <h2>{translations.forPerson || 'for Person'}</h2>
      {/* <p><span className="fw-bold">{translations.chargePerKWh}</span>- {translations.currency}</p> */}
      <p><span className="fw-bold">{translations.installedPower}</span> - {stationPower} {translations.kw}</p>
      {/* <p>{translations.monthlyConsumption} - {translations.kwh}</p> */}
      <p><span className="fw-bold">{translations.requiredArea}</span> - {stationArea.toFixed(2)} {translations.m2}</p>
      {/* <p className="m-0"><span className="fw-bold">{translations.projectCost}</span> - {stationArea.toFixed(2)} {translations.m2}</p> */}
    </div>
  );

  
  const forCompany = (
    <div>
      {" "}
      <h2>{translations.forCompany || 'for Company'}</h2>
      {/* <p><span className="fw-bold">{translations.chargePerKWh}</span>- {translations.currency}</p> */}
      <p><span className="fw-bold">{translations.installedPower}</span> - {stationPower} {translations.kw}</p>
      {/* <p>{translations.monthlyConsumption} - {translations.kwh}</p> */}
      <p><span className="fw-bold">{translations.requiredArea}</span> - {stationArea.toFixed(2)} {translations.m2}</p>
      {/* <p className="m-0"><span className="fw-bold">{translations.projectCost}</span> - {stationArea.toFixed(2)} {translations.m2}</p> */}
    </div>
  );


  return (
    <div className="calcComponent-container">
     {localCalcData.personCompany === 'person' ? forPerson : forCompany}
     <Button onClick={handleSubmit} className="my-4 mt-md-auto mb-md-3 request-price-button rounded-0 calculator-submit-button helvetica-caps border-0">{translations.requestPrice}</Button>
     
    </div>
  );
};

export default CalcComponent;
