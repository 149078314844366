import { createSlice } from "@reduxjs/toolkit";

import Partner1 from "../images/partners/partner-1.png";
import Partner2 from "../images/partners/partner-2.png";
import Partner3 from "../images/partners/partner-3.png";
import Partner4 from "../images/partners/partner-4.png";
import Partner5 from "../images/partners/partner-5.png";
import Partner6 from "../images/partners/partner-6.png";
import Partner7 from "../images/partners/partner-7.png";
import Partner8 from "../images/partners/partner-8.png";
import Partner9 from "../images/partners/partner-9.png";
import Partner10 from "../images/partners/partner-10.png";
import Partner11 from "../images/partners/partner-11.png";
import Partner12 from "../images/partners/partner-12.png";
import Partner13 from "../images/partners/partner-13.png";
import Partner14 from "../images/partners/partner-14.png";
import Partner15 from "../images/partners/partner-15.png";
import Partner16 from "../images/partners/partner-16.png";
import Partner17 from "../images/partners/partner-17.png";
import Partner18 from "../images/partners/partner-18.png";
import Partner19 from "../images/partners/partner-19.png";
import Partner20 from "../images/partners/partner-20.png";
import GermFlag from "../images/flags/germany-flag-icon.png";
import GeorgiaFlag from "../images/flags/georgia-flag-icon.png";
import UKFlag from "../images/flags/united-kingdom-flag-icon.png";
import turkeyFlag from "../images/flags/turkey-flag-icon.png";
import chinaFlag from "../images/flags/china-flag-icon.png";
import canadaFlag from "../images/flags/canada-flag-icon.png";
import switzerlandFlag from "../images/flags/switzerland-flag-icon.png";
import EUFlag from "../images/flags/europe-flag-icon.png";
import en from "../translations/en";
import geo from "../translations/geo";
import arm from "../translations/arm";
import rus from "../translations/rus";

const initialState = [
  {
    name: "Canadian Solar",
    logo: Partner1,
    paragraph: {
      en: en.partnersCanadianSolarDescription,
      geo: geo.partnersCanadianSolarDescription,
      arm: arm.partnersCanadianSolarDescription,
      rus: rus.partnersCanadianSolarDescription,
    },
    flag: canadaFlag,
    page: "https://www.canadiansolar.com/",
  },
  {
    name: "AE solar",
    logo: Partner2,
    paragraph: {
      en: en.partnersAeSolarDescription,
      geo: geo.partnersAeSolarDescription,
      arm: arm.partnersAeSolarDescription,
      rus: rus.partnersAeSolarDescription,
    },
    flag: GermFlag,
    page: "https://ae-solar.com/",
  },
  {
    name: "Astro Energy",
    logo: Partner3,
    paragraph: {
      en: en.partnersAstroDescription,
      geo: geo.partnersAstroDescription,
      arm: arm.partnersAstroDescription,
      rus: rus.partnersAstroDescription,
    },
    flag: chinaFlag,
    page: "https://www.astronergy.com/about-astronergy/",
  },
  {
    name: "Huawei",
    logo: Partner4,
    paragraph: {
      en: en.partnersHuaweiDescription,
      geo: geo.partnersHuaweiDescription,
      arm: arm.partnersHuaweiDescription,
      rus: rus.partnersHuaweiDescription,
    },

    flag: chinaFlag,
    page: "https://solar.huawei.com/cn/",
  },
  {
    name: "SMA Solar",
    logo: Partner5,
    paragraph: {
      en: en.partnersSmaDescription,
      geo: geo.partnersSmaDescription,
      arm: arm.partnersSmaDescription,
      rus: rus.partnersSmaDescription,
    },
    flag: GermFlag,
    page: "https://www.sma.de/",
  },
  {
    name: "KBE",
    logo: Partner6,
    paragraph: {
      en: en.partnersKbeDescription,
      geo: geo.partnersKbeDescription,
      arm: arm.partnersKbeDescription,
      rus: rus.partnersKbeDescription,
    },
    flag: GermFlag,
    page: "https://www.kbe-elektrotechnik.com/",
  },
  {
    name: "Schneider Electric",
    logo: Partner7,
    paragraph: {
      en: en.partnersSchneiderElectricDescription,
      geo: geo.partnersSchneiderElectricDescription,
      arm: arm.partnersSchneiderElectricDescription,
      rus: rus.partnersSchneiderElectricDescription,
    },
    flag: GermFlag,
    page: "https://www.se.com/ww/en/",
  },
  // {
  //   name: "Hikra solar canbles",
  //   logo: Partner8,
  //   paragraph: {
  //     en: en.partnersHikraDescription,
  //     geo: geo.partnersHikraDescription,
  //     arm: arm.partnersHikraDescription,
  //     rus: rus.partnersHikraDescription,
  //   },
  //   flag: GermFlag,
  //   page: "https://www.his-solar.com/",
  // },
  {
    name: "Stäubli International AG",
    logo: Partner9,
    paragraph: {
      en: en.partnersStaubliDescription,
      geo: geo.partnersStaubliDescription,
      arm: arm.partnersStaubliDescription,
      rus: rus.partnersStaubliDescription,
    },
    flag: switzerlandFlag,
    page: "https://www.staubli.com/asia/en/home.html",
  },
  {
    name: "KIRAC",
    logo: Partner10,
    paragraph: {
      en: en.partnersKiracDescription,
      geo: geo.partnersKiracDescription,
      arm: arm.partnersKiracDescription,
      rus: rus.partnersKiracDescription,
    },
    flag: turkeyFlag,
    page: "https://www.kiracmetal.com/",
  },
  {
    name: "HIS",
    logo: Partner11,
    paragraph: {
      en: en.partnersHisDescription,
      geo: geo.partnersHisDescription,
      arm: arm.partnersHisDescription,
      rus: rus.partnersHisDescription,
    },
    flag: turkeyFlag,
    page: "https://www.his-solar.com/",
  },
  {
    name: "suntree",
    logo: Partner12,
    paragraph: {
      en: en.partnersSuntreeDescription,
      geo: geo.partnersSuntreeDescription,
      arm: arm.partnersSuntreeDescription,
      rus: rus.partnersSuntreeDescription,
    },
    flag: chinaFlag,
    page: "https://www.cnsuntree.com",
  },
  {
    name: "ABB",
    logo: Partner13,
    paragraph: {
      en: en.partnersAbbDescription,
      geo: geo.partnersAbbDescription,
      arm: arm.partnersAbbDescription,
      rus: rus.partnersAbbDescription,
    },
    flag: chinaFlag,
    page: "https://global.abb/",
  },
  {
    name: "K2",
    logo: Partner14,
    paragraph: {
      en: en.partnersK2Description,
      geo: geo.partnersK2Description,
      arm: arm.partnersK2Description,
      rus: rus.partnersK2Description,
    },
    flag: GermFlag,
    page: "https://k2-systems.com/",
  },
  {
    name: "Munich Re",
    logo: Partner15,
    paragraph: {
      en: en.partnersMunichreDescription,
      geo: geo.partnersMunichreDescription,
      arm: arm.partnersMunichreDescription,
      rus: rus.partnersMunichreDescription,
    },
    flag: GermFlag,
    page: "https://www.munichre.com/",
  },
  {
    name: "Ariel Re",
    logo: Partner16,
    paragraph: {
      en: en.partnersArielreDescription,
      geo: geo.partnersArielreDescription,
      arm: arm.partnersArielreDescription,
      rus: rus.partnersArielreDescription,
    },
    flag: UKFlag,
    page: "https://www.arielre.com/",
  },
  {
    name: "TÜV Rheinland",
    logo: Partner17,
    paragraph: {
      en: en.partnersTuvRheinlandDescription,
      geo: geo.partnersTuvRheinlandDescription,
      arm: arm.partnersTuvRheinlandDescription,
      rus: rus.partnersTuvRheinlandDescription,
    },
    flag: GermFlag,
    page: "https://www.tuv.com/world/en/",
  },
  {
    name: "UNISON",
    logo: Partner18,
    paragraph: {
      en: en.partnersUnisonDescription,
      geo: geo.partnersUnisonDescription,
      
      rus: rus.partnersUnisonDescription,
    },
    flag: GeorgiaFlag,
    page: "https://unison.ge/",
  },
  {
    name: "GEDF",
    logo: Partner19,
    paragraph: {
      en: en.partnersGedfDescription,
      geo: geo.partnersGedfDescription,
      arm: arm.partnersGedfDescription,
      rus: rus.partnersGedfDescription,
    },
    flag: GeorgiaFlag,
    page: "https://gedf.ge/",
  },
  {
    name: "GEFF",
    logo: Partner20,
    paragraph: {
      en: en.partnersGeffDescription,
      geo: geo.partnersGeffDescription,
      arm: arm.partnersGeffDescription,
      rus: rus.partnersGeffDescription,
    },
    flag: EUFlag,
    page: "https://ebrdgeff.com/",
  },
];

export const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    getAllPartners: (state) => {
      return state;
    },
  },
});

export const { getAllPartners } = partnersSlice.actions;

export default partnersSlice.reducer;
