import React from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ProjectsPageComponent = ({ projects, language }) => {
  const { translations } = useSelector((state) => state.language);
  
  return (
    <Row className="projects-page-component-row">
      {projects.map((project, i) => (
        <div className={`projects-page-col projects-page-col-${i}`} key={i}>
          <div className="projects-page-col-inner-container">
            <div className="projects-page-image-container">
              <img
                src={project.imageSrc}
                className="projects-page-image"
                alt={project.name.en}
              />
            </div>
            <div className="projects-page-image-filter-div"></div>
            <Link
              to={`/projects/${project.name.en}`}
              className="projects-page-project-text-container"
            >
              <div className="projects-page-projects-inner-container">
                <h4 className="projects-page-project-kw">
                  {project.capacity} {translations.kw}
                </h4>
                <div className="projects-page-location-container">
                  <h4 className="projects-page-project-title">
                    {project.name[language]}
                  </h4>
                  <h6 className="projects-page-project-location mb-0">
                    {project.location[language]}
                  </h6>
                </div>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </Row>
  );
};

export default ProjectsPageComponent;
