import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  ButtonGroup,
  ToggleButton,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CalcComponent from "./CalcComponent";
import useCurrencyhook from "../hooks/useCurrencyhook";
import { setCalcData as setReduxCalcData } from "../data/calculatorSlice";

const Calculator = () => {
  const { translations } = useSelector((state) => state.language);
  const { language } = useSelector((state) => state.language);
  

  const dispatch = useDispatch();

  const radios = [
    { name: translations.forPerson, value: "person" },
    { name: translations.forCompany, value: "company" },
  ];

  const panelsPlaceOption = [
    { name: translations.onGround, value: "ground" },
    { name: translations.slopingRoof, value: "Sloping roof" },
    { name: translations.straightRoof, value: "Straight roof" },
  ];

  // const companyPrice = 0.29;
  // const personPrice = 0.27;
  // const groundPrice = 1400;
  // const slopingRoof = 1200;
  // const straightRoofPrice = 1400;
  const emptyfield = [];

  const [localCalcData, setLocalCalcData] = useState({
    personCompany: "",
    // region: "tbilisi",
    panelsPlace: "",
    expense: "",
  });

  const [stationArea, setStationArea] = useState(0);
  const [stationPower, setStationPower] = useState(0);

  const usdToGel = useCurrencyhook() || localStorage.getItem("usdToGel");

  localStorage.setItem("usdToGel", usdToGel);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name === "expense") {
      e.target.addEventListener("keypress", (e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      });
    }
    setLocalCalcData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    e.target
      .closest(`.calc-check-field`)
      ?.classList.remove("calculator-error-class");

    document
      .getElementById(`${e.target.name}-title`)
      ?.classList.remove("calc-title-error-text");
  };

  const roundNumber = (num) => {
    if (num - Math.floor(num) !== 0) {
      if (num - Math.floor(num) > 0 && num - Math.floor(num) <= 0.5) {
        return Math.floor(num) + 0.5;
      } else if (num - Math.floor(num) > 0.5 && num - Math.floor(num) < 1) {
        return Math.ceil(num);
      }
    } else {
      return num;
    }
  };

  // const [emptyField, setEmptyfield] = useState([]) ;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      localCalcData.personCompany &&
      localCalcData.panelsPlace &&
      localCalcData.expense
    ) {
      const annualExpense = +localCalcData.expense * 12;
      const totalStationPower =
        annualExpense /
        (localCalcData.personCompany === "person" ? 0.27 : 0.29) /
        (localCalcData.panelsPlace === "ground" ? 1200 : 1400);

      // const totalExpenseForCompany =
      //   annualExpense / companyPrice / localCalcData.panelsPlace;

      const roundedTotalStationPower = roundNumber(totalStationPower);
      const requiredArea =
        localCalcData.panelsPlace === "Sloping roof"
          ? roundedTotalStationPower * 6
          : roundedTotalStationPower * 12;

      setStationPower(roundedTotalStationPower);
      setStationArea(requiredArea);

      dispatch(
        setReduxCalcData({
          ...localCalcData,
          stationPower: roundedTotalStationPower,
          stationArea: requiredArea,
        })
      );
   
    } else {
      Object.entries(localCalcData)
        .filter((field) => field[1] === "")
        .map((el) => {
          document
            .getElementById(`${el[0]}-group`)
            ?.classList.add("calculator-error-class");
          document
            .getElementById(`${el[0]}-title`)
            ?.classList.add("calc-title-error-text");
          return emptyfield.push(el[0]);
        });
    }
  };

  // useEffect(() => {
  //   if (language === "geo") {
  //     setStationPrice(stationPower * 800 * usdToGel);
  //   } else {
  //     setStationPrice(stationPower * 800);
  //   }
  // }, [language, stationPower, usdToGel]);

  return (
    <Container
      fluid
      className="mt-5 py-4 calculator-page-container page-container"
    >
      <h2>
        {translations.solarPowerPlantCalculator ||
          "Solar Power Plant Calculator"}
      </h2>
      <Row className="pt-4">
        <p className="text-center">{translations.calculatorDescription}</p>
      </Row>
      <Row className="px-0 pt-4">
        <Form
          onSubmit={handleSubmit}
          // onSubmit={handleCalcSubmit}
          noValidate
          className="contact-page-form py-0"
        >
          <Row className="cal-input-row">
            <div className="calc-input-title-container">
              <h5 id={"personCompany-title"} className="calc-input-title">
                {translations.chooseStatus}{" "}
              </h5>
            </div>
            <ButtonGroup
              aria-required
              className="d-flex flex-column flex-md-row px-0 calc-check-field"
              id={`personCompany-group`}
            >
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={"outline-warning"}
                  name="personCompany"
                  className={
                    "py-3 text-dark rounded-0 border-1 calculator-active-button calculator-person-company-button"
                  }
                  value={radio.value}
                  checked={localCalcData.personCompany === radio.value}
                  onChange={handleChange}
                  // onClick={(e) => setCheckedPersonCompany(true)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>
          <Row className="pt-4 calc-input-row">
            <div className="calc-input-title-container">
              <h5 id={`panelsPlace-title`} className="calc-input-title">
                {translations.selectPlaceForPanels}
              </h5>
            </div>
            <ButtonGroup
              id={`panelsPlace-group`}
              className="d-flex flex-column flex-md-row px-0 calc-check-field"
            >
              {panelsPlaceOption.map((place, idx) => (
                <ToggleButton
                  key={idx}
                  id={`panelsPlace-${idx}`}
                  type="radio"
                  variant={"outline-warning"}
                  name="panelsPlace"
                  className={
                    "py-3 text-dark rounded-0 border-1 calculator-active-button calculator-person-company-button"
                  }
                  value={place.value}
                  checked={localCalcData.panelsPlace === place.value}
                  onChange={handleChange}
                >
                  {place.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Row>
          <Row className="pt-4 calc-input-row">
            {/* <h2 className="py-2 fw-bold">{translations.monthlyCost}</h2> */}
            <div className="calc-input-title-container">
              <h5
                id={`expense-title`}
                className="helvetica-caps calc-input-title"
              >
                {translations.enterMonthlyCost}
              </h5>
            </div>
            <Col
              id={`expense-group`}
              sm={10}
              md={6}
              className="px-0 calc-check-field mb-2 md-mb-0"
            >
              <InputGroup className="d-flex flex-column flex-sm-row">
                <Form.Control
                  placeholder={translations.currency}
                  aria-label={translations.currency}
                  aria-describedby="basic-addon2"
                  className="calculator-input px-1 rounded-0 w-100 form-control-responsive"
                  value={localCalcData.expense}
                  name="expense"
                  onChange={handleChange}
                />
              </InputGroup>
            </Col>
            <Col sm={2} md={5} className="ms-auto px-0 ps-sm-1">
              <Button
                className="w-100 w-md-25 px-0 p rounded-0 calculator-submit-button helvetica-caps"
                variant=""
                id="button-addon2"
                type={"submit"}
                // onClick={handleSubmit}
              >
                {translations.calculate}
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row>
        <hr className="calculator-hr mt-5" />
        <h2 className="py-4 mb-0 fw-bold">{translations.calculation}:</h2>
        <CalcComponent
          localCalcData={localCalcData}
          stationArea={stationArea}
          stationPower={stationPower}
        />
      </Row>
    </Container>
  );
};

export default Calculator;
